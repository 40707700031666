import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import axios from "axios";
import "./Login.css";
import BgImage from "../TempAssets/bg.png";
import BgMobileImage from "../TempAssets/bg1.png";
import IdeaImage from "../TempAssets/Group-35.png";
import Title from "../TempAssets/Group-36.png";
import Logo1 from "../TempAssets/speakr.svg";
import Logo2 from "../TempAssets/Group 32.svg";
import Logo3 from "../TempAssets/Group 33.svg";
import Date from "../TempAssets/date.svg";
import Time from "../TempAssets/time.svg";
import Gsk from "../TempAssets/gsk.svg";
import LoginButton from "../TempAssets/button.svg";
import Email from "../TempAssets/email.svg";
import Name from "../TempAssets/name.svg";
import Phone from "../TempAssets/phoneNo.svg";
import State from "../TempAssets/location.svg";
import City from "../TempAssets/city.svg";
import Spec from "../TempAssets/speciality.svg";
import AppleCal from "../TempAssets/apple.svg";
import Desc from "../TempAssets/Group-37.png";
import ClipLoader from "react-spinners/ClipLoader";
import useWindowSize from "./useWindowSize";

const LOGIN_POST_URL = process.env.REACT_APP_LOGIN_POST_URL;
const REG_POST_URL = process.env.REACT_APP_REG_POST_URL;
const GET_STATE_URL = process.env.REACT_APP_GET_STATE_URL;
const GET_SPEC_URL = process.env.REACT_APP_GET_SPEC_URL;

function Login() {
  const windowSize = useWindowSize();

  const [userData, setUserData] = useState({
    fname: "",
    email: "",
    loginEmail: "",
    state: "",
    city: "",
    mobile_number: "",
    title: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [isLoading1, setLoading1] = useState(false);
  //display error
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [specError, setSpecError] = useState("");
  const [emailLoginError, setEmailLoginError] = useState("");

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [specialityId, setSpecialitId] = useState([]);
  const [id, setId] = useState([]);

  //to check inputs are clicked or not
  const [isFnameFocus, setFnameFocus] = useState(false);
  const [isEmailFocus, setEmailFocus] = useState(false);
  const [isEmailLoginFocus, setEmailLoginFocus] = useState(false);
  const [isMobileFocus, setMobileFocus] = useState(false);
  const [name, setName] = useState({
    fname: "",
    lname: "",
  });

  useEffect(() => {
    if (userData.fname.includes(" ")) {
      let index = userData.fname.indexOf(" ");
      let fname = userData.fname.slice(0, index);
      let lname = userData.fname.slice(index + 1, userData.fname.length);
      setName({ ...name, ["fname"]: fname, ["lname"]: lname });
    }
  }, [userData.fname]);

  const handleInput = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    if (id == "mobile_number") {
      value = value.replace(/[^0-9]/g, "");
      setUserData({ ...userData, [id]: value });
    }
    //  if (id == "fname") {
    //    value = value.replace(/ /, "");
    //    setUserData({ ...userData, [id]: value });
    //  }
    setUserData({ ...userData, [id]: value });
  };

  const handleKeyPress = (e) => {
    if (e.key == " " && userData.fname.charAt(0) == " ") {
      setUserData({ ...userData, ["fname"]: userData.fname.replace(" ", "") });
    }

    if (e.key == " " && userData.fname.endsWith(" ")) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    axios
      .get(GET_STATE_URL)
      .then((res) => {
        setStateData([]);
        Object.values(res.data).map((i) => {
          setStateData((prevArray) => [...prevArray, i]);
        });
      });
    axios
      .get(GET_SPEC_URL)
      .then((res) => {
        setSpecialityData([]);
        for (let i = 0; i < res.data.length; i++) {
          setSpecialityData((Prev) => [...Prev, res.data[i].title]);
          setSpecialitId((Prev) => [...Prev, res.data[i]]);
        }
      });
  }, []);

  useEffect(() => {
    if (userData.state) {
      axios
        .get(
          GET_STATE_URL + "?state="+userData.state
        )
        .then((res) => {
          setCityData([]);
          Object.values(res.data).map((i) => {
            setCityData((prevArray) => [...prevArray, i]);
          });
        });
    }
  }, [userData.state]);

  const getState = () => {
    let stateOption = [];
    stateData.sort();
    stateData.map((i) => {
      stateOption.push(<option value={i}>{i}</option>);
    });
    return stateOption;
  };

  const getCity = () => {
    let cityOptions = [];
    cityData.sort();
    cityData.map((i) => {
      cityOptions.push(<option value={i}>{i}</option>);
    });
    return cityOptions;
  };
  const getSpeciality = () => {
    let specOption = [];
    specialityData.sort();
    specialityData.map((i) => {
      specOption.push(<option value={i}>{i}</option>);
    });
    return specOption;
  };

  const login = () => {
    let email = userData.loginEmail;
    setLoading1(true);
    axios
      .post(LOGIN_POST_URL, {
        email: email,
      })
      .then((res) => {
        console.log(res);
        setLoading1(false);
        if (
          res.data.response.message ==
          "You have not registered, please register first"
        ) {
          setEmailLoginError(res.data.response.message);
        } else {
          setEmailLoginError("");
          setEmailLoginFocus(false);
          setUserData({ ...userData, loginEmail: "" });
          // window.location.href = res.data.response.redirect_url;
        }
      })
      .catch((e) => {
        setLoading1(false);
        // console.log(e.response.data?.response.email);
        if (e.response.data?.response?.email) {
          setEmailLoginError("*" + e.response.data?.response?.email);
        } else {
          setEmailLoginError("");
        }
      });
  };
  const register = () => {
    let fname = "";
    let lname = "";
    if (!userData.fname.includes(" ")) {
      fname = userData.fname.trim();
    } else {
      fname = name.fname;
      lname = name.lname;
    }
    let email = userData.email.trim();
    let state = userData.state;
    let city = userData.city;
    let mobile_number = userData.mobile_number;
    let title=id    
    setLoading(true);
    axios
      .post(REG_POST_URL, {
        fname: fname,
        lname: lname ? lname : "",
        email: email,
        state: state,
        city: city,
        mobile_number: mobile_number,
        speciality_id: title,
        link_id: 930,
      })
      .then((res) => {
        setLoading(false);
        setFnameError("");
        setMobileError("");
        setEmailError("");
        setSpecError("");
        setStateError("");
        setCityError("");
        setMobileFocus(false);
        setFnameFocus(false);
        setEmailFocus(false);
        setUserData({
          ...userData,
          fname: "",
          email: "",
          mobile_number: "",
          title: "",
          city: "",
          state: "",
        });
      })
      .catch((e) => {
        setLoading(false);
      //   console.log(e.response.data?.response?.Errors?.fname);
        if (e.response.data?.response?.fname) {
          setFnameError("*" + e.response.data?.response?.fname);
        } else {
          setFnameError("");
        }
        if (e.response.data?.response?.email) {
          setEmailError("*" + e.response.data?.response?.email);
        } else {
          setEmailError("");
        }
        if (e.response.data?.response?.mobile_number) {
          setMobileError(
            "*" + e.response.data?.response?.mobile_number
          );
        } else {
          setMobileError("");
        }
        if (e.response.data?.response?.state) {
          setStateError("*" + e.response.data?.response?.state);
        } else {
          setStateError("");
        }
        if (e.response.data?.response?.city) {
          setCityError("*" + e.response.data?.response?.city);
        } else {
          setCityError("");
        }
        if (e.response.data?.response?.title) {
          setSpecError("*" + e.response.data?.response?.title);
        } else {
          setSpecError("");
        }
      });
  };

  useEffect(() => {
    if (isFnameFocus && userData.fname) {
      setFnameError("");
    } else if (isFnameFocus && userData.fname.length === 0) {
      setFnameError("First Name is required");
    }
    if (!userData.fname.match(/^[A-Za-z ]*$/)) {
      setFnameError("First name must not contain special characters");
    }
    if (isFnameFocus && userData.fname.length >= 25) {
      setFnameError("The First Name may not be greater than 25 characters.");
    }
  }, [userData.fname]);

  useEffect(() => {
    if (
      userData.email.match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
    ) {
      setEmailError("");
    } else if (
      isEmailFocus &&
      !userData.email.match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
    ) {
      setEmailError("Invalid Email Format");
    }
  }, [userData.email]);
  useEffect(() => {
    if (
      userData.loginEmail.match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
    ) {
      setEmailLoginError("");
    } else if (
      isEmailLoginFocus &&
      !userData.loginEmail.match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
    ) {
      setEmailLoginError("Invalid Email Format");
    }
  }, [userData.loginEmail]);

  useEffect(() => {
    if (userData.mobile_number.length === 10) {
      setMobileError("");
    }
    if (isMobileFocus && userData.mobile_number.length == 0) {
      setMobileError("Mobile Number is required");
    } else if (isMobileFocus && userData.mobile_number.length != 10) {
      setMobileError("The mobile number must be of 10 digits long");
    }
  }, [userData.mobile_number]);

  useEffect(() => {
    if (userData.state) {
      setStateError("");
    }
  }, [userData.state]);
  useEffect(() => {
    if (userData.city) {
      setCityError("");
    }
  }, [userData.city]);
  useEffect(() => {
    specialityId.map((i)=>{
      if(i.title === userData.title){
        setId(i.id);
      }
    })
    if (userData.title) {
      setSpecError("");
    }
  }, [userData.title]);

  return (
    <div className="l-main">
      <div className="l-container">
        <div className="l-left">
          <div className="l-bg-imgDiv">
            {windowSize > 880 ? (
              <img src={BgImage} width="100%" className="l-bg-image"></img>
            ) : (
              <img
                src={BgMobileImage}
                width="100%"
                className="l-bg-image"
              ></img>
            )}
          </div>
          <div className="l-data">
            <p className="l-topic">TOPIC</p>
            <hr className="l-line" />
            <p className="l-headline">
              Augmentin 2023
              <br />
              Case Based
              <br />
              Approach for
              <br />
              Tonsillopharyngitis
              <br />
              (Adults)
            </p>
            <hr className="l-line" />
            <div className="l-image-div">
              <img src={IdeaImage} className="l-light-image" />
            </div>
          </div>
        </div>
        <div className="l-middle">
          <div className="l-gsk-logo">
            <img src={Gsk} alt="" width="30" />
          </div>
          <div className="l-title">
            <img src={Title} alt="" className="l-title-img" />
          </div>
          <div className="l-info">
            <div className="l-main-info">
              <img src={Logo1} alt="" className="l-logos" />
              <div
                className="l-dataInfo"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span style={{ fontSize: "14px" }}>Speaker</span>
                <span>Dr. Pragati Shrivastva</span>
              </div>
              <div className="l-profession">
                <span>&nbsp;(MBBS,MD)</span>
              </div>
            </div>
            <div className="l-main-info" style={{ marginLeft: "-2px" }}>
              <img src={Logo2} alt="" className="l-logos" />
              <div className="l-dataInfo">
                <span>Interactive Session</span>
              </div>
            </div>
            <div className="l-main-info">
              <img src={Logo3} alt="" className="l-logos" />
              <div className="l-dataInfo">
                <span>Extensive Q & A</span>
              </div>
            </div>
          </div>
          <div className="l-dates-info">
            <div className="l-dateTime">
              Date:
              <img src={Date} alt="" width="90%" />
            </div>
            <div className="l-dateTime">
              Time:
              <img src={Time} alt="" width="90%" />
            </div>
          </div>
          <div className="l-desc">
            <img src={Desc} alt="" width="30%" />
            <br />
            <br />
            <span>
              Please report adverse events with any GSK product to the company
              at a{" "}
              <span style={{ color: "#FE5714" }}>
                india.pharmacovigilance@gsk.com
              </span>
              . This mail is intended for Indian Healthcare professional only.
              Tradmarks are owned by licensed to the GSK group of companies. All
              rights reserved. GLaxoSmithKline Pharmaceuticals Ltd. Registered
              Office: dr. Annie Besant Road, Mumbai - 400 030 Content lab no.:
              PM-IN-ACA-WBAN-230003 date of Preparation: February 2023
            </span>
          </div>
          <br />
        </div>
        <div className="l-right">
          <div className="l-form-div">
            <div className="l-form-login">
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  marginBottom: "20px",
                }}
              >
                Already Registered:
              </span>
              <div className="l-login-email">
                <img src={Email} alt="" width="7%" className="l-icons" />
                <input
                  type="email"
                  placeholder="Email*"
                  id="loginEmail"
                  className="l-input"
                  onChange={handleInput}
                  value={userData.loginEmail}
                  onFocus={() => setEmailLoginFocus(true)}
                />
              </div>

              <div className="l-required">
                {emailLoginError ? emailLoginError : <br />}
              </div>
              <button className="l-login-button" onClick={login}>
                <img
                  src={LoginButton}
                  alt=""
                  width="40"
                  style={{ marginLeft: "-7px" }}
                />
                <span style={!isLoading1 ? {} : { marginLeft: "10px" }}>
                  {!isLoading1 ? (
                    "LOGIN"
                  ) : (
                    <ClipLoader color="#fff" size="10px"></ClipLoader>
                  )}
                </span>
              </button>
            </div>

            <div className="l-form-login" style={{ paddingTop: "10px" }}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  marginBottom: "20px",
                }}
              >
                Webcast Registration:
              </span>
              <div className="l-login-email">
                <img src={Name} alt="" width="7%" className="l-icons" />
                <input
                  type="text"
                  placeholder="Name*"
                  id="fname"
                  className="l-input"
                  onChange={handleInput}
                  value={userData.fname}
                  onFocus={() => setFnameFocus(true)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="l-required">
                {fnameError ? fnameError : <br />}
              </div>
              <div className="l-login-email">
                <img src={Email} alt="" width="7%" className="l-icons" />
                <input
                  type="email"
                  placeholder="Email*"
                  id="email"
                  className="l-input"
                  onChange={handleInput}
                  value={userData.email}
                  onFocus={() => setEmailFocus(true)}
                />
              </div>
              <div className="l-required">
                {emailError ? emailError : <br />}
              </div>
              <div className="l-login-email">
                <img src={State} alt="" width="7%" className="l-icons" />
                <select
                  name="state"
                  id="state"
                  className="l-input"
                  onChange={handleInput}
                  value={userData.state}
                >
                  <option value="" hidden>
                    State
                  </option>
                  {getState()}
                </select>
              </div>
              <div className="l-required">
                {stateError ? stateError : <br />}
              </div>
              <div className="l-login-email">
                <img src={City} alt="" width="7%" className="l-icons" />
                <select
                  name="city"
                  id="city"
                  className="l-input"
                  onChange={handleInput}
                  value={userData.city}
                >
                  <option value="" hidden>
                    City
                  </option>
                  {getCity()}
                </select>
              </div>
              <div className="l-required">{cityError ? cityError : <br />}</div>
              <div className="l-login-email">
                <img src={Phone} alt="" width="7%" className="l-icons" />
                <input
                  type="tel"
                  id="mobile_number"
                  placeholder="Phone No.*"
                  className="l-input"
                  onChange={handleInput}
                  onFocus={() => setMobileFocus(true)}
                  value={userData.mobile_number}
                />
              </div>
              <div className="l-required">
                {mobileError ? mobileError : <br />}
              </div>
              <div className="l-login-email">
                <img
                  src={Spec}
                  alt=""
                  width="8%"
                  className="l-icons"
                  style={isMobile ? { marginLeft: "-10px" } : {}}
                />
                <select
                  name="specialtiy"
                  id="title"
                  className="l-select"
                  onChange={handleInput}
                  value={userData.title}
                >
                  <option value="Speciality" hidden>
                    Speciality
                  </option>
                  {getSpeciality()}
                </select>
              </div>
              <div className="l-required-spec">
                {specError ? specError : <br />}
              </div>
              <button className="l-login-button" onClick={register}>
                <img
                  src={LoginButton}
                  alt=""
                  width="40"
                  style={{ marginLeft: "-7px" }}
                />
                <span style={!isLoading ? {} : { marginLeft: "10px" }}>
                  {!isLoading ? (
                    "SUBMIT"
                  ) : (
                    <ClipLoader color="#fff" size="10px"></ClipLoader>
                  )}
                </span>
              </button>
            </div>
            <br />
            <div className="l-add-calender">
              <span style={{ fontSize: "16px", fontWeight: "500" }}>
                Add To Calender
              </span>
              <div className="l-calenders">
                <img src={AppleCal} alt="" width="20" />
                <span
                  style={{ fontSize: "14px", color: "#000", cursor: "pointer" }}
                >
                  <a
                    target="_blank"
                    style={{ color: "#000" }}
                    href="https://outlook.live.com/owa/"
                  >
                    Outlook
                  </a>
                </span>
                <img
                  src={AppleCal}
                  alt=""
                  width="20"
                  style={{ marginLeft: "14px" }}
                />
                <span
                  style={{ fontSize: "14px", color: "#000", cursor: "pointer" }}
                >
                  <a
                    target="_blank"
                    style={{ color: "#000" }}
                    href="https://calendar.google.com/calendar/u/0/r"
                  >
                    Google
                  </a>
                </span>
                <img
                  src={AppleCal}
                  alt=""
                  width="20"
                  style={{ marginLeft: "14px" }}
                />
                <span
                  style={{ fontSize: "14px", color: "#000", cursor: "pointer" }}
                >
                  <a
                    target="_blank"
                    style={{ color: "#000" }}
                    href="https://calendar.yahoo.com/"
                  >
                    Yahoo
                  </a>
                </span>
                <img
                  src={AppleCal}
                  alt=""
                  width="20"
                  style={{ marginLeft: "14px" }}
                />
                <span
                  style={{ fontSize: "14px", color: "#000", cursor: "pointer" }}
                >
                  <a
                    target="_blank"
                    style={{ color: "#000" }}
                    href="https://www.icloud.com/calendar"
                  >
                    Apple Calender
                  </a>
                </span>
              </div>
              <br />
              <span style={{ fontSize: "12px", cursor: "pointer" }}>
                <a
                  target="_blank"
                  href="https://privacy.gsk.com/en-us/privacy-notice/"
                  style={{ color: "#428243" }}
                >
                  Please Click here for our Privacy Statement
                </a>
              </span>
              <br />
              <span style={{ fontSize: "12px", cursor: "pointer" }}>
                <a href="" style={{ color: "#428243" }}>
                  Please report adverse events with any GSK products at
                  india.pharmacovigilance@gsk.com
                </a>
              </span>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
